exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-carousel-js": () => import("./../../../src/pages/addCarousel.js" /* webpackChunkName: "component---src-pages-add-carousel-js" */),
  "component---src-pages-add-category-js": () => import("./../../../src/pages/addCategory.js" /* webpackChunkName: "component---src-pages-add-category-js" */),
  "component---src-pages-add-client-js": () => import("./../../../src/pages/addClient.js" /* webpackChunkName: "component---src-pages-add-client-js" */),
  "component---src-pages-add-course-js": () => import("./../../../src/pages/addCourse.js" /* webpackChunkName: "component---src-pages-add-course-js" */),
  "component---src-pages-add-notification-js": () => import("./../../../src/pages/addNotification.js" /* webpackChunkName: "component---src-pages-add-notification-js" */),
  "component---src-pages-add-product-js": () => import("./../../../src/pages/addProduct.js" /* webpackChunkName: "component---src-pages-add-product-js" */),
  "component---src-pages-add-students-work-js": () => import("./../../../src/pages/addStudentsWork.js" /* webpackChunkName: "component---src-pages-add-students-work-js" */),
  "component---src-pages-add-tax-js": () => import("./../../../src/pages/addTax.js" /* webpackChunkName: "component---src-pages-add-tax-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-carousel-js": () => import("./../../../src/pages/manageCarousel.js" /* webpackChunkName: "component---src-pages-manage-carousel-js" */),
  "component---src-pages-manage-category-js": () => import("./../../../src/pages/manageCategory.js" /* webpackChunkName: "component---src-pages-manage-category-js" */),
  "component---src-pages-manage-client-js": () => import("./../../../src/pages/manageClient.js" /* webpackChunkName: "component---src-pages-manage-client-js" */),
  "component---src-pages-manage-course-js": () => import("./../../../src/pages/manageCourse.js" /* webpackChunkName: "component---src-pages-manage-course-js" */),
  "component---src-pages-manage-notification-js": () => import("./../../../src/pages/manageNotification.js" /* webpackChunkName: "component---src-pages-manage-notification-js" */),
  "component---src-pages-manage-portfolio-js": () => import("./../../../src/pages/managePortfolio.js" /* webpackChunkName: "component---src-pages-manage-portfolio-js" */),
  "component---src-pages-manage-pos-js": () => import("./../../../src/pages/managePos.js" /* webpackChunkName: "component---src-pages-manage-pos-js" */),
  "component---src-pages-manage-product-js": () => import("./../../../src/pages/manageProduct.js" /* webpackChunkName: "component---src-pages-manage-product-js" */),
  "component---src-pages-manage-students-work-js": () => import("./../../../src/pages/manageStudentsWork.js" /* webpackChunkName: "component---src-pages-manage-students-work-js" */),
  "component---src-pages-manage-tax-js": () => import("./../../../src/pages/manageTax.js" /* webpackChunkName: "component---src-pages-manage-tax-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pos-bak-js": () => import("./../../../src/pages/pos.bak.js" /* webpackChunkName: "component---src-pages-pos-bak-js" */),
  "component---src-pages-pos-bak-latest-js": () => import("./../../../src/pages/pos.bak.latest.js" /* webpackChunkName: "component---src-pages-pos-bak-latest-js" */),
  "component---src-pages-pos-js": () => import("./../../../src/pages/pos.js" /* webpackChunkName: "component---src-pages-pos-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-table-js": () => import("./../../../src/pages/table.js" /* webpackChunkName: "component---src-pages-table-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

